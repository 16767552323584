@media all and (max-width: 500px) {
  .about-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    padding-top: 24vh;
    padding-left: 12vw;
    padding-right: 8vw;
    width: 80vw;
    position: fixed;
    color: black;
    font-size: 5vw;
    text-align: left;
    letter-spacing: 1px;

    .about-paragraph {
      font-family: "Light" !important;
      font-weight: 500 !important;
    }
  }
}
@media all and (min-width: 501px) and (max-width: 839px) {
  .about-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    padding-top: 28vh;
    padding-left: 12vw;
    padding-right: 8vw;
    width: 80vw;
    position: fixed;
    color: black;
    font-size: 3vw;
    text-align: left;
    letter-spacing: 1px;

    .about-paragraph {
      font-family: "Light" !important;
      font-weight: 500 !important;
    }
  }
}

@media all and (min-width: 840px) and (max-width: 1189px) {
  .about-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    padding-top: 28vh;
    padding-left: 15vw;
    padding-right: 15vw;
    width: 70vw;
    position: fixed;
    color: black;
    font-size: 2.5vw;
    text-align: left;
    letter-spacing: 1px;

    .about-paragraph {
      font-family: "Light" !important;
      font-weight: 500 !important;
    }
  }
}

@media all and (min-width: 1190px) {
  .about-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    padding-top: 28vh;
    padding-left: 22vw;
    width: 60vw;
    position: fixed;
    color: black;
    font-size: 1.25vw;
    text-align: left;
    letter-spacing: 1px;

    .about-paragraph {
      font-family: "Light" !important;
      font-weight: 500 !important;
    }
  }
}
