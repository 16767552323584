@media all and (max-width: 839px) {
  .lifestyle-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    padding-bottom: 6vh;
    display: grid;
    grid-template-columns: 1fr;
    max-width: 100%;

    .loading-container {
      width: 40px; /* Set the desired width */
      height: 40px; /* Set the desired height */
      margin-left: 50vw;
      margin-right: auto;
      margin-top: 40vh;
    }

    .lifestyle-image-container {
      img {
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.loaded {
          opacity: 1;
        }
      }

      .lifestyle-portrait {
        height: auto;
        width: 40vw;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 14vh;
        margin-top: 10vh;
      }

      .portrait-yellow-car {
        height: 70vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 14vh;
        margin-top: 10vh;
      }

      .landscape-yellow-car {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 6vh;
        margin-top: 5vh;
        height: auto;
        width: 70vw;
      }

      .portrait {
        height: 60vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 2vh;
      }

      .lifestyle-landscape-end {
        margin-bottom: 2vh;
        margin-top: 5vh;
        margin-left: 10vw;
        margin-right: 10vw;
        height: auto;
        width: 70vw;
      }

      .landscape {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 5vh;
        margin-top: 15vh;
        height: auto;
        width: 80vw;
      }

      .portrait-fire {
        height: 60vh;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 15vh;
        margin-bottom: 10vh;
      }

      .portrait-fire-start {
        height: 60vh;
        width: auto;
        display: block;
        margin: auto;
        margin-top: 15vh;
        margin-bottom: 10vh;
      }

      .portrait-fire-2 {
        height: 60vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15vh;
        margin-bottom: 10vh;
      }

      .lifestyle-landscape-second {
        margin-left: 10vw;
        margin-right: 20vw;
        margin-bottom: 4vh;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .lifestyle-landscape-third {
        margin-left: 20vw;
        margin-right: 10vw;
        margin-bottom: 2vh;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .lifestyle-landscape-fourth {
        margin-left: 10vw;
        margin-right: 30vw;
        margin-bottom: 10vh;
        margin-top: 15vh;
        height: auto;
        width: 50vw;
      }

      .lifestyle-landscape-fourth-end {
        margin-left: 20vw;
        margin-right: 20vw;
        margin-bottom: 10vh;
        margin-top: 15vh;
        height: auto;
        width: 50vw;
      }

      .lifestyle-landscape-fifth {
        margin-left: 30vw;
        margin-right: 10vw;
        margin-bottom: 2vh;
        margin-top: 5vh;
        height: auto;
        width: 50vw;
      }

      .small-landscape {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 15vh;
        margin-top: 10vh;
        height: auto;
        width: 70vw;
      }

      .lifestyle-landscape-sixth {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 10vh;
        margin-top: 7vh;
        height: auto;
        width: 70vw;
      }

      .landscape-7 {
        margin-left: 20vw;
        margin-right: 10vw;
        margin-bottom: 5vh;
        margin-top: 10vh;
        height: auto;
        width: 60vw;
      }

      .landscape-8 {
        margin-left: 10vw;
        margin-right: 20vw;
        margin-bottom: 5vh;
        margin-top: 5vh;
        height: auto;
        width: 70vw;
      }

      .landscape-9 {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 20vh;
        margin-top: 12vh;
        height: auto;
        width: 70vw;
      }

      .landscape-wide {
        margin-left: 5vw;
        margin-right: 5vw;
        margin-bottom: 5vh;
        margin-top: 5vh;
        height: auto;
        width: 80vw;
      }

      .portrait-start {
        padding-top: 8vh;
        height: auto;
        width: 50vw;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .portrait-end {
        padding-top: 8vh;
        height: 60vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5vh;
      }

      .eunice-image2 {
        margin-top: 10vh;
        height: 70vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 9vh;
      }

      .eunice-image1 {
        width: 300px;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 5vw;
        margin-bottom: 15vh;
        margin-top: 10vh;
      }
    }
  }

  // inherits button-bottom css properties elsewhere
  .button-bottom {
    bottom: 25px;
    position: fixed;
    border: none;
    margin-left: 47vw;
    margin-right: 47vw;
    z-index: 999;
    width: 6vw;

    button {
      z-index: 1;
      display: inline-block;
      background-color: transparent;
      color: grey;
      text-align: center;
      text-decoration: none;
      font-size: 35px;
      font-weight: 600;
      cursor: pointer;
      border: none;
      padding: 0px 9px 5px 9px;
    }

    button:hover {
      color: black;
    }
  }
}

@media all and (min-width: 840px) and (max-width: 1189px) {
  .lifestyle-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    padding-bottom: 6vh;
    display: grid;
    grid-template-columns: 1fr;
    max-width: 100%;

    .loading-container {
      width: 40px; /* Set the desired width */
      height: 40px; /* Set the desired height */
      margin-left: 50vw;
      margin-right: auto;
      margin-top: 40vh;
    }

    .lifestyle-image-container {
      img {
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.loaded {
          opacity: 1;
        }
      }

      .lifestyle-portrait {
        height: 60vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 14vh;
        margin-top: 10vh;
      }

      .portrait-yellow-car {
        height: 60vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 14vh;
        margin-top: 10vh;
      }

      .landscape-yellow-car {
        margin-bottom: 10vh;
        margin-left: 20vw;
        margin-right: 10vw;
        margin-top: 2vh;
        height: auto;
        width: 50vw;
      }

      .landscape {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 5vh;
        margin-top: 14vh;
        height: auto;
        width: 70vw;
      }

      .portrait {
        height: 65vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 2vh;
      }

      .lifestyle-landscape-end {
        margin-bottom: 2vh;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-top: 5vh;
        height: auto;
        width: 70vw;
      }

      .portrait-fire {
        height: 60vh;
        width: auto;
        display: block;
        margin-left: 20vw;
        margin-right: auto;
        margin-top: 15vh;
        margin-bottom: 10vh;
      }

      .portrait-fire-start {
        height: 60vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15vh;
        margin-bottom: 10vh;
      }

      .portrait-fire-2 {
        height: 60vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15vh;
        margin-bottom: 10vh;
      }

      .lifestyle-landscape-second {
        margin-left: 20vw;
        margin-right: 10vw;
        margin-bottom: 4vh;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .lifestyle-landscape-third {
        margin-left: 20vw;
        margin-right: 10vw;
        margin-bottom: 2vh;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .lifestyle-landscape-fourth {
        margin-left: 10vw;
        margin-right: 30vw;
        margin-bottom: 10vh;
        margin-top: 15vh;
        height: auto;
        width: 60vw;
      }

      .lifestyle-landscape-fourth-end {
        margin-left: 20vw;
        margin-right: 10vw;
        margin-bottom: 10vh;
        margin-top: 15vh;
        height: auto;
        width: 70vw;
      }

      .lifestyle-landscape-fifth {
        margin-left: 20vw;
        margin-right: 10vw;
        margin-bottom: 2vh;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .small-landscape {
        margin-left: 20vw;
        margin-right: 10vw;
        margin-bottom: 15vh;
        margin-top: 10vh;
        height: auto;
        width: 60vw;
      }

      .lifestyle-landscape-sixth {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 10vh;
        margin-top: 7vh;
        height: auto;
        width: 70vw;
      }

      .landscape-7 {
        margin-left: 20vw;
        margin-right: 10vw;
        margin-bottom: 5vh;
        margin-top: 10vh;
        height: auto;
        width: 60vw;
      }

      .landscape-8 {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 5vh;
        margin-top: 5vh;
        height: auto;
        width: 70vw;
      }

      .landscape-9 {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 20vh;
        margin-top: 12vh;
        height: auto;
        width: 70vw;
      }

      .landscape-wide {
        margin-left: 5vw;
        margin-right: 5vw;
        margin-bottom: 5vh;
        margin-top: 5vh;
        height: auto;
        width: 80vw;
      }
      .portrait-start {
        padding-top: 8vh;
        height: 60vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .portrait-end {
        padding-top: 8vh;
        height: 50vh;
        width: auto;
        display: block;
        margin-left: 20vw;
        margin-right: auto;
        margin-bottom: 5vh;
      }

      .eunice-image2 {
        margin-top: 12vh;
        height: 60vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 9vh;
      }

      .eunice-image1 {
        max-width: 400px;
        height: auto;
        display: block;
        padding: 5vw;
        margin-bottom: 15vh;
        margin-top: 10vh;
      }
    }
  }

  // inherits button-bottom css properties elsewhere
  .button-bottom {
    bottom: 25px;
    position: fixed;
    border: none;
    margin-left: 47vw;
    margin-right: 47vw;
    width: 6vw;
    z-index: 999;

    button {
      z-index: 1;
      display: inline-block;
      background-color: transparent;
      color: grey;
      text-align: center;
      text-decoration: none;
      font-size: 30px;
      font-weight: 600;
      cursor: pointer;
      border: none;
      padding: 0px 9px 5px 9px;
    }

    button:hover {
      color: black;
    }
  }
}

@media all and (min-width: 1190px) {
  .lifestyle-container {
    animation: fadeIn 2s forwards;
    animation-delay: 0.1s;
    padding-bottom: 9vh;
    display: grid;
    grid-template-columns: 2fr;
    max-width: 100%;

    .loading-container {
      width: 40px; /* Set the desired width */
      height: 40px; /* Set the desired height */
      margin-left: 50vw;
      margin-right: auto;
      margin-top: 40vh;
    }

    .lifestyle-image-container {
      img {
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.loaded {
          opacity: 1;
        }
      }

      .eunice-image2 {
        margin-top: 7vh;
        height: 70vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 9vh;
      }

      .portrait-yellow-car {
        height: 70vh;
        width: auto;
        margin-left: 15vw;
        margin-right: 5vw;
        margin-bottom: 8vh;
        margin-top: 10vh;
      }

      .landscape-yellow-car {
        margin-bottom: 10vh;
        margin-left: 5vw;
        margin-top: 2vh;
        height: auto;
        width: 30vw;
      }

      .portrait {
        height: 70vh;
        width: auto;
        display: block;
        margin-left: 50vw;
        margin-right: auto;
        margin-top: 15vh;
        margin-bottom: 10vh;
      }

      .lifestyle-portrait {
        height: 80vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 8vh;
        margin-top: 10vh;
      }

      .lifestyle-landscape-end {
        margin-bottom: 2vh;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-top: 5vh;
        height: auto;
        width: 80vw;
      }

      .portrait-fire {
        height: 70vh;
        width: auto;
        display: block;
        margin-left: 30vw;
        margin-right: auto;
        margin-top: 15vh;
        margin-bottom: 10vh;
      }

      .portrait-fire-start {
        height: 70vh;
        width: auto;
        display: block;
        margin-left: 40vw;
        margin-right: auto;
        margin-top: 15vh;
        margin-bottom: 10vh;
      }

      .portrait-fire-2 {
        height: 75vh;
        width: auto;
        display: block;
        margin-left: 40vw;
        margin-right: auto;
        margin-top: 15vh;
        margin-bottom: 10vh;
      }

      .lifestyle-landscape-second {
        margin-left: 20vw;
        margin-right: 20vw;
        margin-bottom: 4vh;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .lifestyle-landscape-third {
        margin-left: 40vw;
        margin-right: 10vw;
        margin-bottom: 2vh;
        margin-top: 5vh;
        height: auto;
        width: 50vw;
      }

      .lifestyle-landscape-fourth {
        margin-left: 10vw;
        margin-right: 40vw;
        margin-bottom: 10vh;
        margin-top: 15vh;
        height: auto;
        width: 50vw;
      }

      .lifestyle-landscape-fourth-end {
        margin-left: 20vw;
        margin-right: 50vw;
        margin-bottom: 10vh;
        margin-top: 15vh;
        height: auto;
        width: 30vw;
      }

      .lifestyle-landscape-fifth {
        margin-left: 50vw;
        margin-right: 10vw;
        margin-bottom: 2vh;
        margin-top: 5vh;
        height: auto;
        width: 40vw;
      }

      .small-landscape {
        margin-left: 35vw;
        margin-right: 35vw;
        margin-bottom: 15vh;
        margin-top: 10vh;
        height: auto;
        width: 30vw;
      }

      .lifestyle-landscape-sixth {
        margin-left: 10vw;
        margin-right: 30vw;
        margin-bottom: 10vh;
        margin-top: 7vh;
        height: auto;
        width: 60vw;
      }

      .landscape-7 {
        margin-left: 40vw;
        margin-right: 10vw;
        margin-bottom: 5vh;
        margin-top: 10vh;
        height: auto;
        width: 50vw;
      }

      .landscape-8 {
        margin-left: 10vw;
        margin-right: 30vw;
        margin-bottom: 5vh;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .landscape-9 {
        margin-left: 30vw;
        margin-right: 10vw;
        margin-bottom: 20vh;
        margin-top: 12vh;
        height: auto;
        width: 60vw;
      }

      .landscape-wide {
        margin-left: 5vw;
        margin-right: 5vw;
        margin-bottom: 5vh;
        margin-top: 5vh;
        height: auto;
        width: 90vw;
      }
      .portrait-start {
        padding-top: 8vh;
        height: 75vh;
        width: auto;
        display: block;
        margin-left: 30vw;
        margin-right: auto;
        margin-bottom: 5vh;
      }

      .landscape {
        margin-left: 20vw;
        margin-right: 20vw;
        margin-top: 7vh;
        margin-bottom: 4vh;
        height: auto;
        width: 60vw;
      }

      .portrait-end {
        padding-top: 8vh;
        height: 75vh;
        width: auto;
        display: block;
        margin-left: 45vw;
        margin-right: auto;
        margin-bottom: 15vh;
        margin-top: 10vh;
      }
    }

    .eunice-image1 {
      margin-top: 10vh;
      margin: auto;
      padding: 2vw;
      height: auto;
      width: 40vw;
      margin-left: 20vw;
    }

    .eunice-image2 {
      padding-top: 10vh;
      height: 70vh;
      width: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .button-bottom {
    bottom: 25px;
    position: fixed;
    border: none;
    margin-left: 50vw;
    margin-right: 50vw;
    z-index: 999;

    button {
      z-index: 1;
      display: inline-block;
      background-color: transparent;
      color: grey;
      text-align: center;
      text-decoration: none;
      font-size: 30px;
      font-weight: 600;
      cursor: pointer;
      border: none;
      padding: 0px 9px 5px 9px;
    }

    button:hover {
      color: black;
    }
  }
}
