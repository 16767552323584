@media all and (min-width: 1190px) {
  .contact-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    padding-top: 35vh;
    padding-left: 40vw;
    width: 30vw;
    position: fixed;
    color: grey;
    text-align: left;

    .contact-high a {
      font-size: 1.5vw;
      letter-spacing: 1px;
      color: grey;
      text-decoration: none;
      font-family: "Light" !important;
      font-weight: 500 !important;
    }

    .contact-high a:hover {
      color: black;
      text-decoration: none;
    }

    .contact-low a {
      font-size: 1vw;
      letter-spacing: 1px;
      color: grey;
      text-decoration: none;
      font-family: "Light" !important;
      font-weight: 500 !important;
    }

    .contact-low a:hover {
      color: black !important;
      text-decoration: none;
    }
  }
}

@media all and (min-width: 840px) and (max-width: 1189px) {
  .contact-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    padding-top: 35vh;
    padding-left: 40vw;
    padding-right: 30vw;
    width: 30vw;
    position: fixed;
    color: grey;
    font-size: 25px;
    text-align: left;

    .contact-high a {
      color: grey;
      text-decoration: none;
      font-family: "Light" !important;
      font-weight: 500 !important;
    }

    .contact-high a:hover {
      color: black !important;
      text-decoration: none;
    }

    .contact-low a {
      color: grey;
      text-decoration: none;
      font-family: "Light" !important;
      font-weight: 500 !important;
    }

    .contact-low a:hover {
      color: black !important;
      text-decoration: none;
    }
  }
}

@media all and (max-width: 500px) {
  .contact-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    padding-top: 35vh;
    padding-left: 29vw;
    padding-right: 31vw;
    width: 40vw;
    position: fixed;
    color: grey;
    font-size: 4vw;
    text-align: left;

    .contact-high a {
      color: grey;
      text-decoration: none;
      letter-spacing: 1px;
      font-family: "Light" !important;
      font-weight: 500 !important;
    }

    .contact-high a:hover {
      color: black !important;
      text-decoration: none;
    }

    .contact-low a {
      color: grey;
      text-decoration: none;
      letter-spacing: 1px;
      font-family: "Light" !important;
      font-weight: 500 !important;
    }

    .contact-low a:hover {
      color: black !important;
      text-decoration: none;
    }
  }
}

@media all and (min-width: 501px) and (max-width: 839px) {
  .contact-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    padding-top: 35vh;
    padding-left: 34vw;
    padding-right: 26vw;
    width: 40vw;
    position: fixed;
    color: grey;
    font-size: 20px;
    text-align: left;

    .contact-high a {
      color: grey;
      text-decoration: none;
      font-family: "Light" !important;
      font-weight: 500 !important;
    }

    .contact-high a:hover {
      color: black !important;
      text-decoration: none;
    }

    .contact-low a {
      color: grey;
      text-decoration: none;
      font-family: "Light" !important;
      font-weight: 500 !important;
    }

    .contact-low a:hover {
      color: black !important;
      text-decoration: none;
    }
  }
}
