@media all and (max-width: 500px) {
  .sidenav {
    display: none;
  }

  .bottomnav {
    display: none;
  }

  // container for whole sidenav div
  .mobile-nav {
    position: fixed;
    z-index: 1;
    text-align: left;
    margin-top: 0;
    padding-top: 0;

    .top-center {
      position: fixed;
      margin-left: 32vw;
      margin-right: auto;
      display: block;
      margin-top: 22px;
      text-align: center;
      font-size: 27px;
      background-color: transparent;
      z-index: 999;
      .nav-link3 {
        display: block;
        text-decoration: none;
        color: black;
        letter-spacing: 0px;
        text-transform: uppercase !important;
      }

      .nav-link3:hover,
      .active:hover {
        color: black;
      }

      .active {
        color: black;
        text-decoration: line-through;
        font-weight: 500;
      }
    }

    // displays menu on toggle
    .menuNav {
      position: fixed;
      background: transparent;
      left: 0;
      // dictates space between hamburger icon and menu items
      height: 88vh;
      width: 0;
      overflow: hidden;
      max-width: 100px;
      z-index: 999;
    }

    .menuNav:hover {
      color: black;
    }

    .menuNav.showMenu {
      width: 100%;
      animation: fadeIn 0.5s backwards;
      animation-delay: 0.1s;
    }

    // properties for hamburger menu and x
    button {
      margin: 20px;
      background-color: white;
      border: none;
    }

    .mobile-dropdown {
      // affecting all links that are not marc waldow
      .nav-link {
        padding: 2vh 3vh;
        font-size: 20px;
        display: block;
        text-decoration: none;
        color: grey;
        font-family: "Light" !important;
        font-weight: 700 !important;
      }

      .nav-link:hover,
      .active:hover {
        color: black;
      }

      // marc link
      .nav-link3 {
        padding: 2vh 3vh;
        font-size: 4vw;
        display: block;
        text-decoration: none;
        color: grey;
        letter-spacing: 0px;
        text-transform: uppercase !important;
      }

      .nav-link3:hover,
      .active:hover {
        color: black;
      }

      .active {
        color: black;
        text-decoration: line-through;
        font-weight: 500;
      }
    }
  }
}

@media all and (min-width: 501px) and (max-width: 766px) {
  .sidenav {
    display: none;
  }

  .bottomnav {
    display: none;
  }

  // container for whole sidenav div
  .mobile-nav {
    position: fixed;
    z-index: 1;
    text-align: left;
    margin-top: 0;
    padding-top: 0;

    .top-center {
      position: fixed;
      margin-left: 35vw;
      margin-right: auto;
      display: block;
      margin-top: 23px;
      text-align: center;
      font-size: 27px;
      background-color: transparent;
      z-index: 999;
      .nav-link3 {
        display: block;
        text-decoration: none;
        color: black;
        letter-spacing: 0px;
        text-transform: uppercase !important;
      }

      .nav-link3:hover,
      .active:hover {
        color: black;
      }

      .active {
        color: black;
        text-decoration: line-through;
        font-weight: 500;
      }
    }

    // displays menu on toggle
    .menuNav {
      position: fixed;
      background: transparent;
      left: 0;
      // dictates space between hamburger icon and menu items
      height: 88vh;
      width: 0;
      overflow: hidden;
      max-width: 120px;
      z-index: 999;
    }

    .menuNav:hover {
      color: black;
    }

    .menuNav.showMenu {
      width: 100%;
      animation: fadeIn 0.5s backwards;
      animation-delay: 0.1s;
    }

    // properties for hamburger menu and x
    button {
      margin: 20px;
      // margin-top: 22px;
      background-color: white;
      border: none;
    }

    .mobile-dropdown {
      // affecting all links that are not marc waldow
      .nav-link {
        padding: 2vh 3vh;
        font-size: 20px;
        display: block;
        text-decoration: none;
        color: grey;
        font-family: "Light" !important;
        font-weight: 700 !important;
      }

      .nav-link:hover,
      .active:hover {
        color: black;
      }

      // marc link
      .nav-link3 {
        padding: 2vh 3vh;
        font-size: 4vw;
        display: block;
        text-decoration: none;
        color: grey;
        letter-spacing: 0px;
        text-transform: uppercase !important;
      }

      .nav-link3:hover,
      .active:hover {
        color: black;
      }

      .active {
        color: black;
        text-decoration: line-through;
        font-weight: 500;
      }
    }
  }
}

@media all and (min-width: 767px) and (max-width: 839px) {
  .sidenav {
    display: none;
  }

  .bottomnav {
    display: none;
  }

  // container for whole sidenav div
  .mobile-nav {
    position: fixed;
    z-index: 1;
    text-align: left;
    margin-top: 0;
    padding-top: 0;

    .top-center {
      position: fixed;
      margin-left: 38vw;
      margin-right: auto;
      margin-top: 23px;
      text-align: center;
      font-size: 25px;
      background-color: transparent;
      z-index: 999;
      .nav-link3 {
        display: block;
        text-decoration: none;
        color: black;
        letter-spacing: 0px;
        text-transform: uppercase !important;
      }

      .nav-link3:hover,
      .active:hover {
        color: black;
      }

      .active {
        color: black;
        text-decoration: line-through;
        font-weight: 500;
      }
    }

    // displays menu on toggle
    .menuNav {
      position: fixed;
      background: transparent;
      left: 0;
      // dictates space between hamburger icon and menu items
      height: 88vh;
      width: 0;
      overflow: hidden;
      max-width: 100px;
      z-index: 999;
    }

    .menuNav:hover {
      color: black;
    }

    .menuNav.showMenu {
      width: 100%;
      animation: fadeIn 0.5s backwards;
      animation-delay: 0.1s;
    }

    // properties for hamburger menu and x
    button {
      margin: 20px;
      background-color: white;
      border: none;
    }

    .mobile-dropdown {
      // affecting all links that are not marc waldow
      .nav-link {
        padding: 2vh 3vh;
        font-size: 20px;
        display: block;
        text-decoration: none;
        color: grey;
        font-family: "Light" !important;
        font-weight: 700 !important;
      }

      .nav-link:hover,
      .active:hover {
        color: black;
      }

      // marc link
      .nav-link3 {
        padding: 2vh 3vh;
        font-size: 4vw;
        display: block;
        text-decoration: none;
        color: grey;
        letter-spacing: 0px;
        text-transform: uppercase !important;
      }

      .nav-link3:hover,
      .active:hover {
        color: black;
      }

      .active {
        color: black;
        text-decoration: line-through;
        font-weight: 500;
      }
    }
  }
}

@media all and (min-width: 840px) and (max-width: 1189px) {
  .sidenav {
    display: none;
  }

  .bottomnav {
    display: none;
  }

  // container for whole sidenav div
  .mobile-nav {
    position: fixed;
    z-index: 1;
    text-align: left;
    margin-top: 0;
    padding-top: 0;

    .top-center {
      position: fixed;
      margin-left: 41vw;
      margin-top: 26px;
      text-align: center;
      font-size: 2.75vw;
      background-color: transparent;
      z-index: 1;
      .nav-link3 {
        display: block;
        text-decoration: none;
        color: black;
        letter-spacing: 0px;
        text-transform: uppercase !important;
      }

      .nav-link3:hover,
      .active:hover {
        color: black;
      }

      .active {
        color: black;
        text-decoration: line-through;
        font-weight: 500;
      }
    }

    // displays menu on toggle
    .menuNav {
      position: fixed;
      background: transparent;
      left: 0;
      // dictates space between hamburger icon and menu items
      height: 88vh;
      width: 0;
      overflow: hidden;
      max-width: 260px;
      z-index: 999;
    }

    .menuNav:hover {
      color: black;
    }

    .menuNav.showMenu {
      width: 100%;
      animation: fadeIn 0.5s backwards;
      animation-delay: 0.1s;
    }

    // properties for hamburger menu and x
    button {
      margin: 2.5vw;
      background-color: white;
      border: 4px solid black;
      border: none;
    }

    .mobile-dropdown {
      // affecting all links that are not marc waldow
      .nav-link {
        padding: 2vh 3vh;
        font-size: 2.5vw;
        display: block;
        text-decoration: none;
        color: grey;
        font-family: "Light" !important;
        font-weight: 700 !important;
      }

      .nav-link:hover,
      .active:hover {
        color: black;
      }

      // marc link
      .nav-link3 {
        padding: 2vh 3vh;
        font-size: 3.5vw;
        display: block;
        text-decoration: none;
        color: grey;
        letter-spacing: 0px;
        text-transform: uppercase !important;
      }

      .nav-link3:hover,
      .active:hover {
        color: black;
      }

      .active {
        color: black;
        text-decoration: line-through;
        font-weight: 500;
      }
    }
  }
}

@media all and (min-width: 1190px) {
  .mobile-nav {
    display: none;
  }

  .top-center {
    display: none;
  }

  .sidenav {
    height: 100%;
    max-width: 430px;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
    padding-left: 15px;
    background-color: transparent;
    text-align: left;

    .side-menu-top {
      // about and work links
      .nav-link {
        margin-top: 2vh;
        padding: 14px 16px;
        // was 2vw
        font-size: 1vw;
        letter-spacing: 3px;
        display: block;
        text-decoration: none;
        color: grey;
        font-weight: 700 !important;
        font-family: "Light" !important;
      }

      // marc waldow link
      .nav-link3 {
        letter-spacing: 0px;
        margin-top: 2vh;
        padding: 14px 16px;
        font-size: 2vw;
        display: block;
        text-decoration: none;
        color: black;
        // padding inside red menu boxes constrained by max-width above. currently 90 is max
        padding-right: 80px;
        text-transform: uppercase !important;
        font-weight: 500;
      }

      .nav-link3:hover {
        color: black;
      }

      .nav-link:hover,
      .active:hover {
        background-color: transparent;
        color: black;
      }

      .active {
        color: black;
        text-decoration: line-through;
        font-weight: 500;
      }
    }
  }

  .bottomnav {
    background-color: transparent;
    z-index: 1;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    .sidenav-bottom {
      z-index: 2;
      float: left;
      padding-bottom: 25px;
      position: relative;
      background-color: transparent;
      max-width: 300px;
      position: fixed;
      z-index: 1;
      bottom: 0;
      text-align: left;

      .contact-box {
        display: block;

        .nav-link2 {
          padding-top: 2vh;
          text-decoration: none;
        }

        .nav-link3 {
          font-weight: 700 !important;
          font-family: "Light" !important;
        }

        .nav-link2:hover {
          color: black;
        }

        .active:hover {
          background-color: transparent;
          color: black;
        }

        .active {
          color: black;
          text-decoration: line-through;
          font-weight: 500;
        }

        .nav-link2:hover {
          color: black;
        }

        .nav-link3:hover {
          color: black;
        }

        // contact menu item
        a {
          letter-spacing: 3px;
          display: block;
          color: grey;
          text-decoration: none;
          font-size: 1vw;
          padding: 14px 16px;
          padding-right: 7vw;
        }

        a:hover {
          color: black;
        }
      }
    }
  }
}
