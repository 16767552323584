.loading-icon {
  width: 40px; /* Set the desired width */
  height: 40px; /* Set the desired height */
  padding: 10vw;
  align-items: center;
  vertical-align: center;
}

@media all and (max-width: 839px) {
  .home-image-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    letter-spacing: 3px;

    a:link {
      text-decoration: none;
    }

    .lifestyle-container {
      text-align: center;
      width: fit-content;
      block-size: fit-content;
      padding-top: 15vh;
      margin: auto;

      img {
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.loaded {
          opacity: 1;
        }
      }

      .work-title {
        margin-top: 0.5vh;
        color: grey;
        font-size: 2vw;
        font-weight: 700 !important;
        font-family: "Light" !important;
      }

      .work-title:hover {
        color: black !important;
      }

      .lifestyle {
        margin-top: 8vh;
        width: 90vw;
        height: auto;
      }
    }

    .clients-container {
      padding-left: 20vw;
      text-align: center;
      width: fit-content;
      block-size: fit-content;
      padding-bottom: 6vh;

      img {
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.loaded {
          opacity: 1;
        }
      }

      .work-title {
        margin-top: 0.5vh;
        color: grey;
        font-size: 2vw;
        font-weight: 700 !important;
        font-family: "Light" !important;
      }

      .work-title:hover {
        color: black !important;
      }

      .clients {
        margin-top: 10vh;
        width: 60vw;
        height: auto;
      }
    }
  }
}

@media all and (min-width: 840px) and (max-width: 1189px) {
  .home-image-container {
    letter-spacing: 3px;

    a:link {
      text-decoration: none;
    }

    .lifestyle-container {
      text-align: center;
      width: fit-content;
      block-size: fit-content;
      padding-top: 15vh;
      margin: auto;

      img {
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.loaded {
          opacity: 1;
        }
      }

      .work-title {
        margin-top: 0.5vh;
        color: grey;
        font-size: 1.25vw;
        font-weight: 700 !important;
        font-family: "Light" !important;
      }

      .work-title:hover {
        color: black !important;
      }

      .lifestyle {
        margin-top: 8vh;
        width: 50vw;
        height: auto;
      }
    }

    .clients-container {
      padding-left: 20vw;
      text-align: center;
      width: fit-content;
      block-size: fit-content;
      padding-bottom: 6vh;

      img {
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.loaded {
          opacity: 1;
        }
      }

      .work-title {
        margin-top: 0.5vh;
        color: grey;
        font-size: 1.25vw;
        font-weight: 700 !important;
        font-family: "Light" !important;
      }

      .work-title:hover {
        color: black !important;
      }

      .clients {
        margin-top: 10vh;
        width: 60vw;
        height: auto;
      }
    }
  }
}

@media all and (min-width: 1190px) {
  .home-image-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    letter-spacing: 3px;

    .lifestyle-container {
      text-align: center;
      width: fit-content;
      block-size: fit-content;
      margin: auto;

      img {
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.loaded {
          opacity: 1;
        }
      }

      .work-title {
        margin-top: 0.5vh;
        color: grey;
        font-weight: 700 !important;
        font-family: "Light" !important;
        font-size: 1vw;
      }

      .work-title:hover {
        color: black;
      }

      .lifestyle {
        padding-top: 11vh;
        width: auto;
        height: 75vh;
      }
    }

    a:link {
      text-decoration: none;
    }

    .clients-container {
      margin-left: 15vw;
      text-align: center;
      width: fit-content;
      block-size: fit-content;
      padding-bottom: 14vh;
      padding-top: 3vh;

      img {
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.loaded {
          opacity: 1;
        }
      }

      .work-title {
        margin-top: 0.5vh;
        color: grey;
        font-weight: 700 !important;
        font-family: "Light" !important;
        font-size: 1vw;
      }

      .work-title:hover {
        color: black;
      }

      .clients {
        margin-top: 10vh;
        width: 50vw;
        height: auto;
      }
    }
  }
}
