@media all and (max-width: 500px) {
  .work-container {
    padding-left: 36vw;
    padding-right: 45vw;
    width: 5vw;
    padding-top: 30vh;
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;

    .nav-link-projects {
      margin-top: 1.5vh;
      padding: 14px 16px;
      font-size: 18px;
      display: block;
      text-decoration: none;
      color: grey;
      text-align: left;
      letter-spacing: 3px;
      font-weight: 700 !important;
      font-family: "Light" !important;
    }

    .nav-link-projects:hover,
    .active:hover {
      background-color: transparent;
      color: black !important;
      font-weight: 500;
    }

    .active {
      color: black;
      text-decoration: underline;
      font-weight: 500;
    }
  }
}

@media all and (min-width: 501px) and (max-width: 839px) {
  .work-container {
    padding-left: 40vw;
    padding-right: 50vw;
    width: 10vw;
    padding-top: 30vh;
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;

    .nav-link-projects {
      margin-top: 1.5vh;
      padding: 14px 16px;
      font-size: 18px;
      display: block;
      text-decoration: none;
      color: grey;
      text-align: left;
      letter-spacing: 3px;
      font-weight: 700 !important;
      font-family: "Light" !important;
    }

    .nav-link-projects:hover,
    .active:hover {
      background-color: transparent;
      color: black !important;
      font-weight: 500;
    }

    .active {
      color: black;
      text-decoration: underline;
      font-weight: 500;
    }
  }
}

@media all and (min-width: 840px) and (max-width: 1189px) {
  .work-container {
    padding-left: 44vw;
    padding-right: 46vw;
    width: 10vw;
    padding-top: 30vh;
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;

    .nav-link-projects {
      margin-top: 1.5vh;
      padding: 14px 16px;
      font-size: 19px;
      display: block;
      text-decoration: none;
      color: grey;
      text-align: left;
      letter-spacing: 3px;
      font-weight: 700 !important;
      font-family: "Light" !important;
    }

    .nav-link-projects:hover,
    .active:hover {
      background-color: transparent;
      color: black !important;
      font-weight: 500;
    }

    .active {
      color: black;
      text-decoration: underline;
      font-weight: 500;
    }
  }
}

@media all and (min-width: 1190px) {
  .work-container {
    padding-left: 42vw;
    padding-right: 48vw;
    width: 10vw;
    margin-top: 30vh;
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;

    .nav-link-projects {
      letter-spacing: 2px;
      padding: 14px 16px;
      padding-right: 270px;
      padding-top: 2vh;
      // was 1.8vw
      font-size: 1vw;
      display: block;
      text-decoration: none;
      color: grey;
      text-align: left;
      font-weight: 700 !important;
      font-family: "Light" !important;
    }

    .nav-link-projects:hover,
    .active:hover {
      background-color: transparent;
      color: black;
      font-weight: 500;
    }

    .active {
      color: black;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}
