@font-face {
  font-family: "Light";
  src:
    local("FuturaLT-Light"),
    url("./assets/fonts/FuturaLT-Light.ttf") format("truetype");
}

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none; /* Internet Explorer */
  scrollbar-width: none; /* Firefox */
  font-family: "Gloock", serif;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
