.loading-icon {
  width: 40px; /* Set the desired width */
  height: 40px; /* Set the desired height */
  padding: 10vw;
  align-items: center;
  vertical-align: center;
}

@media all and (max-width: 839px) {
  .clients-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    padding-bottom: 6vh;
    display: grid;
    grid-column: 1;
    margin: 0;

    .clients-image-container {
      img {
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.loaded {
          opacity: 1;
        }
      }

      .clients {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-top: 10vh;
        height: auto;
        width: 70vw;
      }

      .clients-left {
        margin-left: 20vw;
        margin-right: 20vw;
        margin-top: 10vh;
        height: auto;
        width: 50vw;
      }

      .clients-right {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-top: 15vh;
        height: auto;
        width: 70vw;
      }

      .clients-fourth {
        margin-left: 20vw;
        margin-right: 10vw;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .clients-fifth {
        margin-left: 10vw;
        margin-right: 20vw;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .clients-sixth {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-top: 5vh;
        height: auto;
        width: 70vw;
      }
      .clients-eighth {
        margin-left: 5vw;
        margin-right: 20vw;
        margin-top: 5vh;
        height: auto;
        width: 65vw;
      }

      .clients-ninth {
        margin-left: 20vw;
        margin-right: 10vw;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .clients-tenth {
        margin-left: 10vw;
        margin-right: 20vw;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .clients-eleven {
        margin-left: 5vw;
        margin-right: 10vw;
        margin-top: 5vh;
        height: auto;
        width: 75vw;
      }

      .clients-12 {
        margin-left: 20vw;
        margin-right: 10vw;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .clients-end {
        margin-left: 20vw;
        margin-right: 10vw;
        margin-top: 20vh;
        height: auto;
        width: 60vw;
      }

      .portrait-end {
        height: 60vh;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10vh;
        margin-bottom: 2vh;
        display: block;
      }

      .portrait {
        padding-top: 5vh;
        height: 60vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5vh;
      }

      .landscape {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 5vh;
        margin-top: 14vh;
        height: auto;
        width: 70vw;
      }

      .landscape-1 {
        margin-left: 10vw;
        margin-right: 5vw;
        margin-bottom: 5vh;
        margin-top: 5vh;
        height: auto;
        width: 75vw;
      }

      .landscape-2 {
        margin-left: 10vw;
        margin-right: 15vw;
        margin-bottom: 5vh;
        margin-top: 0;
        height: auto;
        width: 75vw;
      }

      .landscape-end {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-top: 5vh;
        margin-bottom: 10vh;
        height: auto;
        width: 70vw;
      }

      .portrait-1 {
        height: 60vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4vh;
        margin-bottom: 5vh;
      }

      .portrait-2 {
        height: 50vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20vh;
        margin-top: 10vh;
      }

      .portrait-start {
        height: 50vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5vh;
        margin-top: 7vh;
      }

      .portrait-start-2 {
        height: 60vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5vh;
        margin-top: 7vh;
      }
    }

    // inherits button-bottom css properties elsewhere
    .button-bottom {
      bottom: 20px;
      position: fixed;
      border: none;
      margin-left: 47vw;
      margin-right: 47vw;
      width: 6vw;
      z-index: 999;

      button {
        z-index: 1;
        display: inline-block;
        background-color: transparent;
        color: grey;
        text-align: center;
        text-decoration: none;
        font-size: 35px;
        font-weight: 600;
        cursor: pointer;
        border: none;
        padding: 0px 9px 5px 9px;
      }

      button:hover {
        color: black;
      }
    }
  }
}

@media all and (min-width: 840px) and (max-width: 1189px) {
  .clients-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    padding-bottom: 6vh;
    display: grid;
    grid-column: 1;

    .clients-image-container {
      img {
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.loaded {
          opacity: 1;
        }
      }

      .clients {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-top: 10vh;
        height: auto;
        width: 80vw;
      }

      .clients-left {
        margin-left: 20vw;
        margin-right: 20vw;
        margin-top: 10vh;
        height: auto;
        width: 60vw;
        margin-bottom: 5vh;
      }

      .clients-right {
        margin-left: 30vw;
        margin-right: 20vw;
        margin-top: 8vh;
        margin-bottom: 5vh;
        height: auto;
        width: 50vw;
      }

      .clients-fourth {
        margin-left: 20vw;
        margin-right: 20vw;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .clients-fifth {
        margin-left: 10vw;
        margin-right: 20vw;
        margin-top: 5vh;
        height: auto;
        width: 70vw;
      }

      .clients-sixth {
        margin-left: 30vw;
        margin-right: 10vw;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
        margin-bottom: 5vh;
      }

      .clients-eighth {
        margin-left: 30vw;
        margin-right: 10vw;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
        margin-bottom: 5vh;
      }

      .clients-ninth {
        margin-left: 10vw;
        margin-right: 30vw;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .clients-tenth {
        margin-left: 35vw;
        margin-right: 15vw;
        margin-top: 5vh;
        height: auto;
        width: 50vw;
      }

      .clients-eleven {
        margin-left: 20vw;
        margin-right: 20vw;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .clients-12 {
        margin-left: 20vw;
        margin-right: 20vw;
        margin-top: 5vh;
        height: auto;
        width: 60vw;
      }

      .clients-end {
        margin-left: 30vw;
        margin-right: 20vw;
        margin-top: 20vh;
        height: auto;
        width: 50vw;
      }

      .portrait-end {
        height: 60vh;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10vh;
        margin-bottom: 2vh;
        display: block;
      }

      .portrait {
        height: 70vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5vh;
        padding-top: 14vh;
      }

      .landscape {
        margin-left: 16vw;
        margin-right: 16vw;
        margin-bottom: 5vh;
        margin-top: 10vh;
        height: auto;
        width: 68vw;
      }

      .landscape-1 {
        margin-left: 25vw;
        margin-right: 15vw;
        margin-bottom: 5vh;
        margin-top: 15vh;
        height: auto;
        width: 60vw;
      }

      .landscape-2 {
        margin-left: 20vw;
        margin-right: 15vw;
        margin-bottom: 5vh;
        margin-top: 3vh;
        height: auto;
        width: 65vw;
      }

      .landscape-end {
        margin-left: 10vw;
        margin-right: 16vw;
        margin-top: 5vh;
        margin-bottom: 5vh;
        height: auto;
        width: 74vw;
      }

      .portrait-1 {
        height: 60vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5vh;
        margin-top: 5vh;
      }

      .portrait-2 {
        height: 50vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20vh;
        margin-top: 10vh;
      }

      .portrait-start {
        height: 60vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2vh;
        margin-top: 20vh;
      }

      .portrait-start-2 {
        height: 90vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5vh;
        margin-top: 10vh;
      }
    }

    // inherits button-bottom css properties elsewhere
    .button-bottom {
      bottom: 25px;
      position: fixed;
      border: none;
      margin-left: 50vw;
      margin-right: 50vw;
      z-index: 999;

      button {
        z-index: 1;
        display: inline-block;
        background-color: transparent;
        color: grey;
        text-align: center;
        text-decoration: none;
        font-size: 30px;
        font-weight: 600;
        cursor: pointer;
        border: none;
        padding: 0px 9px 5px 9px;
      }

      button:hover {
        color: black;
      }
    }
  }
}

@media all and (min-width: 1190px) {
  .clients-container {
    animation: fadeIn 1s backwards;
    animation-delay: 0.1s;
    padding-bottom: 9vh;
    display: grid;
    grid-column: 2;

    .clients-image-container {
      img {
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.loaded {
          opacity: 1;
        }
      }

      .clients {
        margin-left: 20vw;
        margin-right: 20vw;
        margin-top: 10vh;
        height: auto;
        width: 60vw;
      }

      .clients-left {
        margin-left: 8vw;
        margin-right: 5vw;
        margin-top: 13vh;
        margin-bottom: 5vw;
        height: auto;
        width: 40vw;
      }

      .clients-fourth {
        margin-left: 20vw;
        margin-right: 40vw;
        margin-top: 13vh;
        margin-bottom: 5vw;
        height: auto;
        width: 40vw;
      }

      .clients-fifth {
        margin-left: 30vw;
        margin-right: 10vw;
        margin-top: 10vh;
        height: auto;
        width: 60vw;
      }

      .clients-sixth {
        margin-left: auto;
        padding-left: 15vw;
        margin-right: auto;
        margin-top: 15vh;
        height: auto;
        width: 30vw;
      }

      .clients-eighth {
        margin-left: 20vw;
        margin-right: 20vw;
        margin-top: 15vh;
        height: auto;
        width: 60vw;
      }

      .clients-ninth {
        margin-left: auto;
        padding-left: 15vw;
        margin-right: auto;
        margin-top: 8vh;
        padding-bottom: 5vh;
        height: auto;
        width: 20vw;
      }

      .clients-tenth {
        margin-left: 8vw;
        margin-right: 5vw;
        margin-top: 20vh;
        margin-bottom: 0;
        height: auto;
        width: 40vw;
      }

      .clients-eleven {
        margin-left: 20vw;
        margin-right: 20vw;
        margin-top: 10vh;
        height: auto;
        width: 60vw;
      }

      .clients-12 {
        margin-left: 10vw;
        margin-right: 15vw;
        margin-top: 20vh;
        margin-bottom: 5vw;
        height: auto;
        width: 60vw;
      }

      .clients-right {
        margin-left: auto;
        padding-left: 5vw;
        margin-right: auto;
        margin-top: 15vh;
        height: auto;
        width: 30vw;
      }

      .clients-end {
        margin-left: 30vw;
        margin-right: 20vw;
        margin-top: 20vh;
        height: auto;
        width: 50vw;
      }

      .portrait-end {
        height: 70vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15vh;
      }

      .portrait {
        padding-top: 14vh;
        margin-bottom: 3vh;
        height: 80vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .landscape {
        margin-left: 20vw;
        margin-right: 16vw;
        margin-bottom: 5vh;
        margin-top: 5vh;
        height: auto;
        width: 64vw;
      }

      .landscape-1 {
        margin-left: 20vw;
        margin-right: 20vw;
        margin-bottom: 5vh;
        margin-top: 10vh;
        height: auto;
        width: 60vw;
      }

      .landscape-2 {
        margin-left: 10vw;
        margin-right: auto;
        margin-bottom: 5vh;
        margin-top: 15vh;
        height: auto;
        width: 35vw;
      }

      .landscape-end {
        margin-left: 10vw;
        margin-right: 16vw;
        margin-top: 5vh;
        height: auto;
        width: 74vw;
      }

      .portrait {
        height: 70vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .portrait-1 {
        height: 70vh;
        width: auto;
        margin-left: 15vw;
        margin-bottom: 20vh;
        margin-top: 8vh;
      }

      .portrait-2 {
        height: 50vh;
        width: auto;
        display: block;
        margin-left: 60vw;
        margin-right: auto;
        margin-bottom: 20vh;
        margin-top: 10vh;
      }

      .portrait-start {
        padding-top: 10vh;
        height: 80vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: 35vw;
        margin-bottom: 15vh;
      }

      .portrait-start-2 {
        padding-top: 10vh;
        height: 85vh;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: 35vw;
        margin-bottom: 2vh;
      }
    }

    .button-bottom {
      bottom: 25px;
      position: fixed;
      border: none;
      margin-left: 50vw;
      margin-right: 50vw;
      z-index: 999;

      button {
        z-index: 1;
        display: inline-block;
        background-color: transparent;
        color: grey;
        text-align: center;
        text-decoration: none;
        font-size: 30px;
        font-weight: 600;
        cursor: pointer;
        border: none;
        padding: 0px 9px 5px 9px;
      }

      button:hover {
        color: black;
      }
    }
  }
}
